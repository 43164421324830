<template>
  <div>
    <div class="newsletter-wrap">
      <form autocomplete="on" @submit.prevent.stop="submitEmail">
        <h2 class="newsletter-headline">Subscribe to our newsletter</h2>
        <label>
          <span class="newsletter-label"> Your Email Address </span>
          <div class="newsletter-input-wrap">
            <input
              ref="input"
              v-model.lazy.trim="email"
              required
              placeholder="john@doe.com"
              autocomplete="email"
              type="email"
              :invalid="hasErrors"
              class="newsletter-input"
            />
            <button
              class="newsletter-submit"
              aria-label="Submit E-Mail Address"
            >
              <SvgIcon
                icon="chevron-right"
                class="newsletter-icon"
                aria-hidden="true"
              />
            </button>
          </div>
        </label>
      </form>
    </div>

    <Alert
      v-if="successMessage"
      type="success"
      :title="successMessage"
      size="small"
      dismissible
    />
    <Alert
      v-if="failureMessage"
      type="error"
      :title="failureMessage"
      size="small"
      dismissible
    />
  </div>
</template>

<script>
  import Alert from "~/components/Alert"

  export default {
    components: { Alert },

    data() {
      return {
        errorMessage: null,
        email: "",
        hasErrors: false,
        successMessage: false,
        failureMessage: false,
      }
    },
    watch: {
      email() {
        if (this.hasErrors) {
          this.$refs.input.setCustomValidity("")
        }
      },
    },
    methods: {
      async submitEmail() {
        if (await this.klaviyoRequest()) {
          this.email = ""
          this.showForm = false
          this.successMessage = "Success! Thank you for subscribing."
        } else {
          this.failureMessage =
            "There was an error processing your request, please try again later."
        }
      },
      async klaviyoRequest() {
        try {
          await $fetch(
            `https://a.klaviyo.com/client/subscriptions/?company_id=${this.$config.public.KLAVIYO_PUBLIC_KEY}`,
            {
              method: "POST",
              body: {
                data: {
                  type: "subscription",
                  attributes: {
                    list_id: "VUh8zQ", // "CandleScience Newsletter" in Klaviyo
                    email: this.email,
                  },
                },
              },
              headers: {
                revision: "2023-02-22",
                "content-type": "application/json",
              },
            },
          )
          return true
        } catch (error) {
          console.error(error)
          return false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .newsletter-wrap {
    position: relative;
    color: currentColor;
  }

  .newsletter-headline {
    @apply h5;
    margin: 0;
  }

  .newsletter-input-wrap {
    margin-top: 1em;
    position: relative;
  }
  .newsletter-input {
    @extend %input-style;

    text-align: left;
    width: 100%;
    padding: $base-spacing * 4 $base-spacing * 10 $base-spacing * 4
      $base-spacing * 4;
    box-sizing: border-box;
    height: $base-spacing * 10;
  }

  .newsletter-label {
    display: block;
    margin-bottom: $base-spacing * 1;
  }

  .newsletter-submit {
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    padding: 0 $base-spacing * 2;

    display: flex;
    align-items: center;
    position: absolute;
    border: none;
    background: none;
  }

  .newsletter-icon {
    color: $gray-shape;
    width: 24px;
    height: 24px;
  }
</style>
